import React from "react"
import Footer from "../components/Footer"
import Nav from "../components/Nav"
import {  StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from '../components/seo';



const BlogPost = () => {
    return (
        <>
            <Seo title="About" />
            <Nav />
            <div className="bg-gray-100 min-h-screen">

                <div className="m-auto container md:py-20 py-0">

                    <div className="bg-gray-50 shadow-xl min-h-screen flex flex-col max-w-4xl m-auto md:rounded-xl rounded-none">

                        {/* post heading */}

                        <div className="md:py-10 py-4 space-y-4 md:px-10 px-4">
                            <p className="text-md">
                                /
                                <Link to="/about" className="hover:text-pink-600 hover:underline">
                                    about
                                </Link>
                            </p>

                            <h1 className="md:text-7xl text-5xl font-semibold">
                                About Maitais At Sunset
                            </h1>


                            <h1 className="text-md">By, Mai & Tai</h1>
                        </div>

                        {/* post Image */}

                        <StaticImage src="../images/about.jpg" alt={`about us image of hawaii`} className="object-cover" />

                        {/* post Body */}

                        <div className="md:px-10 px-4 md:pb-20 pb-8 md:pt-16 pt-6 md:text-xl text-md max-w-3xl m-auto leading-loose">



                            {/* Content */}
                            <div className="space-y-5">
                                <h2 className="text-5xl font-bold">Our Mission</h2>

                                <p>
                                    MaiTais At Sunset mission is to share positivity through life stories and experiences.
                                </p>
                                <p>
                                    Join Mai and Tai every Aloha Friday for Pau Hana time and share a magical sunset, great conversation, and a choice tropical drink to sip on. You can almost feel the light trade winds and smell the pikake flower scented air. The ocean waves gently run up and down the nearby shore, tempting you to dip your toes in the warm water while the soft sand squishes around your feet.
                                </p>
                                <p>

                                    We set the intention that our message is always uplifting and healing. For anyone who listens to our words we hope that you find joy, inspiration, and healing, always. How exciting to be able to share moments of fabulous fun and lots of laughter! Are You Ready? Let’s do this!
                                </p>

                           
                            <h2 className="text-5xl font-bold pt-10">About Mai</h2>
                            <p>
                            My name is Maile, but my friends call me Mai. I am an energy artist infusing healing energies into my artistic creations. I love all forms of artistic expression, paintings, and drawings on a multitude of canvases. I enjoy pottery, photography, and all forms of media production. I am blessed with two beautiful children who are also talented, successful individual artists.
                            </p>
                            <p>
                            I believe life is a journey of joyous moments and experiences that transform each of us into our best selves. My only goal in this life is to live as many happy moments as I can while sharing joy with people all over the world. When I leave this earth, I will go with the knowledge that I was so fortunate to share my adventures with others, while they too, forge their own magnificent life journeys. Each of us has a beautiful soul that blossoms with each day that passes
                            </p>
                            <p>
                            If I leave anything behind, may it be artistic works that will continue to heal others and bring happiness to their lives.
                            </p>
                            <p>
                            I set the intention that every day when I wake up, to experience many moments of joy. And when I go to sleep, I set the intention to have dreams and guidance full of wisdom and prosperity.
                            </p>

                            <h2 className="text-5xl font-bold pt-10">About Tai</h2>
                            <p>Howdy and Aloha! I’m Tai and am so excited to be on this journey with my best friend Mai! We decided on a random night that we should share our fun adventures and extensive knowledge of all things Hawai’i. In addition, we felt like our lives were filled with never-ending stories and fond memories, thus Mai Tais at Sunset was born.</p>
                            <p>
                            My background is pretty diverse. I was born and raised in Texas and consider myself a true cowgirl at heart. Although I have lived in several states and cities, my favorite of course is Hawai’i! A nice mix of Southern Aloha! I have a Ph.D. and work in healthcare; however, I believe being a writer/entrepreneur is my true calling. I have proudly served in the Army for almost 20 years. I love animals of every shape and size. I’m also a serious Disney, Harry Potter, Star Wars, Marvel, DC, and Star Trek fan, so you will probably hear me talk about that until the cows come home. :D I’m all about family and love being back in my home state. Warning: I may talk about food endlessly. 
                            </p>

                            <p>
                            Through this podcast and venture, I hope to inspire others and connect with my family and friends all over the world. Life is too short so, live it fearlessly! 
                            </p>

                            </div>

                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        </>
    )
}

export default BlogPost

